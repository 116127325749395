import {
    ActionFunctionArgs,
    data,
    LoaderFunctionArgs,
    redirect,
    useLoaderData,
} from 'react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateToken } from '~/auth.server';
import Spinner from '~/components/ui/spinner.component';
import { getConfigValue } from '~/config/config.server';
import { getAccount } from '~/lib/api-gateway/service.server';
import { validateCredentials } from '~/lib/pos/service.server';
import { paths } from '~/paths';
import {
    createUserSession,
    getSession,
    isLoggedIn,
    sessionStorage,
} from '~/session.server';
import * as Sentry from '@sentry/react';
import { SearchParam } from '~/search-param.type';

interface LoaderData {
    adminLoginUrl: string;
    error: string | null;
}
export async function loader({ request }: LoaderFunctionArgs) {
    const requestUrl = new URL(request.url);
    const adminLoginUrl = getConfigValue('admin.admin_login_url');
    const token = requestUrl.searchParams?.get(SearchParam.TOKEN);
    if (token) {
        const { accountId, readyAccountId, isValid } = await validateToken(
            token
        );
        if (isValid) {
            const hasBillAccount = await hasBillingAccount(accountId).catch(
                (err) => {
                    Sentry.withScope(function (scope) {
                        scope.setExtra('token', token);
                        scope.setExtra('accountId', accountId);
                        scope.setExtra('readyAccountId', readyAccountId);
                        scope.setExtra('isValid', isValid);
                        scope.setExtra(
                            'Custom Message',
                            'hasBillingAccount check failed in loader'
                        );
                        Sentry.captureException(err);
                    });
                    return false;
                }
            );
            // if the token is valid (thus having an accountId and readyAccountId) we create the session
            // else we keep the user on the login page
            if (hasBillAccount) {
                // token has been validated and a session will be created, so we can remove the token now from the url to keep it cleaner.
                // Info: We keep all other searchParams. The function getRedirectToPath() will decide where to redirect and will add all current
                requestUrl.searchParams?.delete(SearchParam.TOKEN);
                return createUserSession({
                    request,
                    accountId,
                    readyAccountId,
                    remember: true,
                    redirectTo: getRedirectPath(requestUrl),
                });
            }
        } else {
            // if a token was set, that is not valid or has no bill account, we want to remove it from the search params
            requestUrl.searchParams?.delete(SearchParam.TOKEN);
        }
    }

    const loggedIn = await isLoggedIn(request);
    if (loggedIn) {
        return redirect(getRedirectPath(requestUrl));
    }

    const session = await getSession(request);
    const dataObject = { adminLoginUrl, error: session.get('error') };

    return data(dataObject, {
        headers: {
            'Set-Cookie': await sessionStorage.destroySession(session),
        },
    });
}

export async function action({ request }: ActionFunctionArgs) {
    const session = await getSession(request);
    const form = await request.formData();
    const username = form.get('username');
    const password = form.get('password');
    const remember = form.get('remember') === 'on';

    const validationRes = await validateCredentials(
        username?.toString() || '',
        password?.toString() || ''
    );

    if (!validationRes.success || validationRes.code !== 'success') {
        session.flash('error', 'login.errors.invalid_credentials');

        // Redirect back to the login page with errors.
        return redirect(paths.login(), {
            headers: {
                'Set-Cookie': await sessionStorage.commitSession(session),
            },
        });
    }

    if (!validationRes.salesforce_id || !validationRes.salesforceAccount_id) {
        session.flash('error', 'login.errors.generic');

        // Redirect back to the login page with errors.
        return redirect(paths.login(), {
            headers: {
                'Set-Cookie': await sessionStorage.commitSession(session),
            },
        });
    }

    const hasBillAccount = await hasBillingAccount(
        validationRes.salesforceAccount_id
    ).catch((err) => {
        Sentry.withScope(function (scope) {
            scope.setExtra(
                'salesforceAccount_id',
                validationRes.salesforceAccount_id
            );
            scope.setExtra(
                'Custom Message',
                'hasBillingAccount check failed in action'
            );
            Sentry.captureException(err);
        });
        return false;
    });
    if (!hasBillAccount) {
        session.flash('error', 'login.errors.billing_account_non_existing');

        // Redirect back to the login page with errors.
        return redirect(paths.login(), {
            headers: {
                'Set-Cookie': await sessionStorage.commitSession(session),
            },
        });
    }

    // Login succeeded, send them to the home page.
    return createUserSession({
        request,
        accountId: validationRes.salesforceAccount_id,
        readyAccountId: validationRes.salesforce_id,
        remember,
        redirectTo: getRedirectPath(new URL(request.url)),
    });
}

/**
 * This function returns a url path. If the 'redirectTo' searchParam is set, it will return the value of it (which should be a valid path) and append all other searchParams.
 * If it is not set, then it will return the index path '/' and append all other searchParams.
 * @param url
 * @returns
 */
function getRedirectPath(url: URL): string {
    const redirectToPath = url.searchParams?.get(SearchParam.REDIRECT_TO);
    url.searchParams.delete(SearchParam.REDIRECT_TO);
    return `${redirectToPath || '/'}?${url.searchParams.toString()}`;
}

async function hasBillingAccount(accountId: string): Promise<boolean> {
    return getAccount(accountId).then((res) => {
        const { id, account_number } = res.result;
        if (id && account_number) {
            return true;
        }
        return false;
    });
}

export default function Login() {
    const { adminLoginUrl, error } = useLoaderData<LoaderData>();
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = () => setIsSubmitting(true);

    return (
        <main
            id='content'
            role='main'
            className='w-full max-w-md mx-auto p-6'
        >
            <div className='mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-900 dark:border-neutral-700'>
                <div className='p-4 sm:p-7'>
                    <div className='mt-5'>
                        <form
                            method='POST'
                            onSubmit={handleSubmit}
                        >
                            <div className='grid gap-y-4'>
                                <div>
                                    <label
                                        htmlFor='username'
                                        className='block text-sm mb-2 dark:text-white'
                                    >
                                        {t('login.username')}
                                    </label>
                                    <div className='relative'>
                                        <input
                                            type='text'
                                            id='username'
                                            name='username'
                                            className='py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-r2o-blue focus:ring-r2o-blue disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600'
                                            required
                                            aria-describedby='username-error'
                                        />
                                        <div className='hidden absolute inset-y-0 end-0 pointer-events-none pe-3'>
                                            <svg
                                                className='size-5 text-red-500'
                                                width='16'
                                                height='16'
                                                fill='currentColor'
                                                viewBox='0 0 16 16'
                                                aria-hidden='true'
                                            >
                                                <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z' />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='flex justify-between items-center'>
                                        <label
                                            htmlFor='password'
                                            className='block text-sm mb-2 dark:text-white'
                                        >
                                            {t('login.password')}
                                        </label>
                                        <a
                                            className='inline-flex items-center gap-x-1 text-sm text-r2o-blue decoration-2 hover:underline focus:outline-none focus:underline font-medium'
                                            href={adminLoginUrl}
                                        >
                                            {t('login.forgot_password')}
                                        </a>
                                    </div>
                                    <div className='relative'>
                                        <input
                                            type='password'
                                            id='password'
                                            name='password'
                                            className='py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-r2o-blue focus:ring-r2o-blue disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600'
                                            required
                                            aria-describedby='password-error'
                                        />
                                        <div className='hidden absolute inset-y-0 end-0 pointer-events-none pe-3'>
                                            <svg
                                                className='size-5 text-red-500'
                                                width='16'
                                                height='16'
                                                fill='currentColor'
                                                viewBox='0 0 16 16'
                                                aria-hidden='true'
                                            >
                                                <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z' />
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex items-center'>
                                    <div className='flex'>
                                        <input
                                            id='remember'
                                            name='remember'
                                            type='checkbox'
                                            className='shrink-0 mt-0.5 border-gray-200 rounded text-r2o-blue focus:ring-r2o-blue dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-r2o-blue dark:checked:border-r2o-blue dark:focus:ring-offset-gray-800'
                                        />
                                    </div>
                                    <div className='ms-3'>
                                        <label
                                            htmlFor='remember'
                                            className='text-sm dark:text-white'
                                        >
                                            {t('login.remember')}
                                        </label>
                                    </div>
                                </div>

                                {error ? (
                                    <div className='text-sm text-red-600 dark:text-white'>
                                        {t(error)}
                                    </div>
                                ) : null}

                                <button
                                    type='submit'
                                    className='w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-r2o-blue text-white hover:bg-r2o-blue focus:outline-none focus:bg-r2o-blue disabled:opacity-50 disabled:pointer-events-none'
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <Spinner
                                            size='sm'
                                            classes='text-white'
                                        />
                                    ) : (
                                        t('login.submit')
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
}
